<template>
  <div>
    <notifications group="notification" position="bottom right" />
    <div class="row">
      <div class="col-md-12">
        <div class="card card-custom card-sticky" id="kt_page_sticky_card">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Add New Company</h3>
            </div>
            <CardToolbar
              @submitEvent="onSubmit"
              back-text="Back to list"
              back-url="/companies/list"
              button-text="Add Company"
            />
          </div>
          <div class="card-body">
            <!--begin::Form-->
            <form class="form" id="brand_form">
              <div class="row">
                <div class="col-xl-2"></div>
                <div class="col-xl-8">
                  <div class="my-5">
                    <div class="form-group row">
                      <label class="col-3">Name</label>
                      <div class="col-9">
                        <input
                          v-model="company.name"
                          class="form-control form-control-solid"
                          type="text"
                          placeholder="Enter company company name"
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-3">OIB</label>
                      <div class="col-9">
                        <input
                          v-model="company.oib"
                          class="form-control form-control-solid"
                          placeholder="Enter company company oib"
                          type="number"
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-3">Address</label>
                      <div class="col-9">
                        <input
                          v-model="company.address"
                          class="form-control form-control-solid"
                          placeholder="Enter company address"
                          type="text"
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-3">Delivery Address</label>
                      <div class="col-9">
                        <input
                          v-model="company.deliveryAddress"
                          class="form-control form-control-solid"
                          placeholder="Enter company delivery address"
                          type="text"
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-3">Email</label>
                      <div class="col-9">
                        <input
                          v-model="company.email"
                          class="form-control form-control-solid"
                          type="email"
                          placeholder="Enter company email"
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-3">Phone</label>
                      <div class="col-9">
                        <input
                          v-model="company.phone"
                          class="form-control form-control-solid"
                          type="text"
                          placeholder="Enter company phone"
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-3">City</label>
                      <div class="col-9">
                        <input
                          v-model="company.city"
                          class="form-control form-control-solid"
                          type="text"
                          placeholder="Enter company city"
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-3">Postal Code</label>
                      <div class="col-9">
                        <input
                          v-model="company.postalCode"
                          class="form-control form-control-solid"
                          type="text"
                          placeholder="Enter company postal code"
                        />
                      </div>
                    </div>
                    <div class="separator separator-dashed mt-0 mb-10"></div>
                    <div class="form-group row">
                      <label class="col-3">Login details:</label>
                      <div class="col-9">
                        <div class="row">
                          <div class="col-6">
                            <input
                              v-model="loginEmail"
                              class="form-control form-control-solid"
                              type="text"
                              placeholder="Enter login email"
                            />
                          </div>
                          <div class="col-6">
                            <input
                              v-model="loginPassword"
                              class="form-control form-control-solid"
                              type="text"
                              placeholder="Enter login password"
                            />
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col">
                            <button
                              @click.prevent="sendLoginDetails"
                              class="btn font-weight-bold btn-light-primary btn-block"
                              :disabled="!loginEmail || !loginPassword"
                            >
                              Send login details
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="separator separator-dashed mt-0 mb-10"></div>
                    <div class="form-group row">
                      <label class="col-3">Payment Type</label>
                      <div class="col-9">
                        <select
                          v-model="company.paymentType"
                          @change="onPaymentTypeChange($event)"
                          class="form-control"
                        >
                          <option value="virman">Virman</option>
                          <option value="credit_card">Credit card</option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-3">Discounts</label>
                      <div class="col-9">
                        <button
                          @click.prevent="addDiscount"
                          class="btn font-weight-bold btn-light-primary"
                        >
                          Add Discount
                        </button>
                        <button
                          @click.prevent="addDiscountBundle"
                          class="btn font-weight-bold btn-light-primary ml-3"
                        >
                          Add Discount Bundle
                        </button>
                        <button
                          v-if="company.discounts.length > 1"
                          @click.prevent="removeAllDiscounts"
                          class="btn font-weight-bold btn-light-danger ml-3"
                        >
                          Remove All Discounts
                        </button>
                      </div>
                    </div>
                    <div
                      v-for="(discount, key) in company.discounts"
                      :key="key"
                      class="row"
                      :class="{ 'mb-0': key + 1 === company.discounts.length }"
                    >
                      <div class="col-3"></div>
                      <div class="col-9">
                        <div class="row">
                          <div class="col-6">
                            <div
                              class="input-group"
                              @click.prevent="
                                toggleProductsCategoriesModal(key)
                              "
                            >
                              <div class="input-group-prepend">
                                <span class="input-group-text">
                                  <i class="la la-chain"></i>
                                </span>
                              </div>
                              <input
                                class="form-control"
                                :class="{
                                  'font-weight-bolder': !discount.root
                                }"
                                :value="discount.title"
                                disabled
                              />
                            </div>
                          </div>
                          <div class="col-4">
                            <div class="input-group">
                              <div class="input-group-prepend">
                                <span class="input-group-text">
                                  <i class="la la-percentage"></i>
                                </span>
                              </div>
                              <input
                                v-model.number="discount.discount"
                                class="form-control"
                                placeholder="Enter category discount"
                              />
                            </div>
                          </div>
                          <div class="col-2">
                            <div class="form-group">
                              <div>
                                <button
                                  @click.prevent="removeDiscount(key)"
                                  class="btn font-weight-bolder btn-light-danger"
                                >
                                  Remove
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="separator separator-dashed mt-0 mb-10"></div>
                    <div class="form-group row">
                      <label class="col-3">Users</label>
                      <div class="col-9">
                        <div class="row mb-5">
                          <div class="col-12">
                            <button
                              @click.prevent="toggleCompanyUsersModal"
                              class="btn font-weight-bold btn-light-primary"
                            >
                              Add User
                            </button>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12">
                            <div class="table-responsive">
                              <table
                                class="table table-head-custom table-head-bg table-borderless table-vertical-center"
                              >
                                <thead>
                                  <tr class="text-uppercase">
                                    <th
                                      style="min-width: 250px"
                                      class="text-left pl-7"
                                    >
                                      <span class="text-dark-75">Name</span>
                                    </th>
                                    <th class="text-left">
                                      <span class="text-dark-75">Email</span>
                                    </th>
                                    <th
                                      style="min-width: 80px"
                                      class="text-right pr-7"
                                    >
                                      <span class="text-dark-75">Actions</span>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="(user, key) in company.users"
                                    :key="key"
                                  >
                                    <td class="pl-7">
                                      <div class="d-flex align-items-center">
                                        <span
                                          class="text-dark-75 font-weight-bolder mb-1 font-size-lg"
                                          >{{ user.personalInfo.name }}
                                          {{ user.personalInfo.surname }}</span
                                        >
                                      </div>
                                    </td>
                                    <td class="text-left">
                                      <span
                                        class="text-dark-75 font-weight-bolder mb-1 font-size-lg"
                                        >{{ user.email }}</span
                                      >
                                    </td>
                                    <td class="ext-right">
                                      <button
                                        @click.prevent="removeUser(key)"
                                        class="btn font-weight-bolder btn-light-danger"
                                      >
                                        Remove
                                      </button>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-2"></div>
              </div>
            </form>
            <!--end::Form-->
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <b-modal
          id="products_categories_modal"
          size="lg"
          centered
          hide-header
          hide-footer
        >
          <div
            class="scroll scroll-pull"
            data-scroll="true"
            data-wheel-propagation="true"
          >
            <div class="table-responsive">
              <table class="table table-borderless mb-0">
                <tbody>
                  <tr
                    v-for="category in filteredCategories"
                    :key="category.id"
                    @click="selectProductCategory(category)"
                    class="cursor-pointer"
                  >
                    <td
                      class="font-size-lg font-weight-bolder text-dark-75 align-middle pb-6"
                    >
                      <span v-if="category.root.title">-- </span>
                      <span v-else></span>
                      {{ category.title }}
                    </td>
                    <td class="text-muted text-right align-middle pb-6">
                      select
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <button
              @click="selectAllProductCategories(filteredCategories)"
              class="btn btn-light-primary btn-block"
            >
              Select All
            </button>
          </div>
        </b-modal>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <b-modal
          id="discounts_bundle_modal"
          size="lg"
          centered
          hide-header
          hide-footer
        >
          <div
            class="scroll scroll-pull"
            data-scroll="true"
            data-wheel-propagation="true"
          >
            <div class="table-responsive">
              <table
                class="table table-head-custom table-head-bg table-vertical-center"
              >
                <thead>
                  <tr class="bg-gray-100 text-left">
                    <th style="min-width: 250px" class="pl-7">Name</th>
                    <th style="min-width: 110px" class="pr-7 text-right">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(bundle, key) in discountsBundles" :key="key">
                    <td class="pl-7" :class="{ 'border-top-0': key === 0 }">
                      {{ bundle.name }}
                    </td>
                    <td
                      class="pr-7 text-right"
                      :class="{ 'border-top-0': key === 0 }"
                    >
                      <button
                        @click="selectDiscountsBundle(key)"
                        class="btn btn-sm btn-primary"
                      >
                        Use
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </b-modal>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <b-modal id="company_users_modal" centered hide-header hide-footer>
          <div
            class="scroll scroll-pull"
            data-scroll="true"
            data-wheel-propagation="true"
          >
            <div class="table-responsive">
              <table class="table table-borderless mb-0">
                <tbody>
                  <tr
                    v-for="user in users"
                    :key="user.id"
                    @click="selectUser(user)"
                    class="cursor-pointer"
                  >
                    <td
                      class="font-size-lg font-weight-bolder text-dark-75 align-middle w-150px pb-6"
                    >
                      {{ user.email }}
                    </td>
                    <td class="text-muted text-right align-middle pb-6">
                      select
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import CardToolbar from '@/view/content/components/card-toolbar/CardToolbar'
import ApiService from '@/core/services/api.service'
import { extend } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'

extend('required', {
  ...required,
  message: 'This field is required'
})

export default {
  name: 'NewCompany',
  components: {
    CardToolbar
  },
  data() {
    return {
      company: {
        name: null,
        oib: null,
        address: null,
        deliveryAddress: null,
        email: null,
        phone: null,
        city: null,
        postalCode: null,
        paymentType: null,
        discounts: [],
        users: [],
        isVerified: true
      },
      categories: [],
      rootCategories: [],
      filteredCategories: [],
      selectedDiscountKey: null,
      selectedCategory: null,

      users: [],
      selectedUser: null,
      isLoaded: false,

      discountsBundles: [],
      isDiscountsBundlesLoaded: false,

      loginEmail: null,
      loginPassword: null
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'All Companies', route: '/companies/list' },
      { title: 'Add New' }
    ])

    ApiService.get(
      `/product_categories`,
      `?order[createdAt]=desc&order[position]=asc&itemsPerPage=90`
    ).then(({ data }) => {
      this.totalItems = data['hydra:totalItems']
      this.categories = [...data['hydra:member']]

      this.parentCategories = this.categories.filter(
        category => category.parent === undefined
      )
      this.childrenCategories = this.categories.filter(
        category => category.parent !== undefined
      )

      this.parentCategories.forEach(parentCategory => {
        this.filteredCategories.push(parentCategory)
        this.childrenCategories.forEach(childrenCategory => {
          if (childrenCategory.root['@id'] === parentCategory.root) {
            this.filteredCategories.push(childrenCategory)
          }
        })
      })
    })

    this.getUsers('/users', '?page=1').then(({ data }) => {
      this.users = [...data['hydra:member']]
    })
  },
  methods: {
    async onSubmit() {
      this.postCompany(this.company)
        .then(response => {
          if (response.status === 201) {
            this.$notify({
              group: 'notification',
              type: 'success',
              title: 'Company successful add in database'
            })
            setTimeout(() => {
              this.$router.push({ name: 'companies-list' })
            }, 500)
          }
        })
        .catch(() => {
          this.$notify({
            group: 'notification',
            type: 'error',
            title: 'Company submit error'
          })
        })
    },
    async postCompany(entity) {
      try {
        ApiService.setHeader()
        return await ApiService.post('companies', entity)
      } catch (e) {
        throw new Error(e)
      }
    },
    async getCategories(resource, slug = '') {
      try {
        return await ApiService.get(resource, slug)
      } catch (e) {
        throw new Error(e)
      }
    },
    async getUsers(resource, slug = '') {
      try {
        return await ApiService.get(resource, slug)
      } catch (e) {
        throw new Error(e)
      }
    },
    addDiscount() {
      this.company.discounts.push({
        id: '',
        root: '',
        title: '',
        slug: '',
        discount: 0
      })
    },
    addDiscountBundle() {
      ApiService.get('discounts_bundles').then(({ data }) => {
        this.discountsBundles = [...data['hydra:member']]
        this.isDiscountsBundlesLoaded = true
      })
      this.$root.$emit('bv::show::modal', 'discounts_bundle_modal')
    },
    removeDiscount(key) {
      const confirmation = confirm('Do you want to delete this discount rule?')
      if (confirmation === false) return

      this.company.discounts.splice(key, 1)
    },
    removeAllDiscounts() {
      const confirmation = confirm('Do you want to delete all discounts rule?')
      if (confirmation === false) return

      this.company.discounts = []
    },
    toggleProductsCategoriesModal(key) {
      this.selectedDiscountKey = key

      this.$root.$emit('bv::show::modal', 'products_categories_modal')
    },
    selectProductCategory(productCategory) {
      this.selectedCategory = productCategory
      this.company.discounts[this.selectedDiscountKey].id = productCategory.id
      this.company.discounts[this.selectedDiscountKey].title =
        productCategory.title
      this.company.discounts[this.selectedDiscountKey].slug =
        productCategory.slug

      this.$root.$emit('bv::hide::modal', 'products_categories_modal')
    },
    selectAllProductCategories(categories) {
      categories.forEach(category => {
        this.company.discounts.push({
          id: category.id,
          root: category.root.title,
          title: category.title,
          slug: category.slug,
          discount: 0
        })
      })

      this.$root.$emit('bv::hide::modal', 'products_categories_modal')
    },
    selectDiscountsBundle(key) {
      this.company.discounts = this.discountsBundles[key]['discounts']

      this.$root.$emit('bv::hide::modal', 'discounts_bundle_modal')
    },
    onPaymentTypeChange() {},
    toggleCompanyUsersModal() {
      this.$root.$emit('bv::show::modal', 'company_users_modal')
    },
    selectUser(user) {
      this.selectedUser = user
      this.company.users.push(user)

      this.$root.$emit('bv::hide::modal', 'company_users_modal')
    },
    removeUser(key) {
      const confirmation = confirm('Do you want to delete this user?')
      if (confirmation === false) return

      this.company.users.splice(key, 1)
    },
    sendLoginDetails() {
      ApiService.get(
        `/send-company-login-details/${this.company.oib}/${this.loginEmail}/${this.loginPassword}`
      ).then(response => {
        if (response.status === 200) {
          this.$notify({
            group: 'notification',
            type: 'success',
            title: 'Login details successful send'
          })
        }
      })
    }
  }
}
</script>
